import { Component, Renderer2, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FiltersService } from '@modules/landing/services/filters.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { cambiarVista } from 'src/app/utils/experience.utils';
import { CookieService } from 'ngx-cookie-service';
import { TypeExp } from '@core/enums/TypeExp';


@Component({
  selector: 'app-landing-experiences',
  templateUrl: './landing-experiences.component.html',
  styleUrls: ['./landing-experiences.component.css', './responsive.css']
})
export class LandingExperiencesComponent implements AfterViewInit {
  @ViewChild('tourBahiaSubMenu') tourBahiaSubMenu: ElementRef | undefined;
  @ViewChild('tourIslaSubMenu') tourIslaSubMenu: ElementRef | undefined;

  changeView = cambiarVista;
  visableEmpresarial: boolean = false;

  constructor(private router: Router, private renderer: Renderer2, private el: ElementRef,
    private filters: FiltersService, @Inject(LOCAL_STORAGE) private storage: StorageService,
    public cookies: CookieService) { }

  rediriectToReservas() {
    if(!(this.filters.getFiltersLanding().exp == TypeExp.EMPRESARIAL)) {
      this.router.navigate(['/reservas']);
    }else{
      this.visableEmpresarial = true;
      setTimeout(() => {
        window.open('https://wa.me/573052765639', '_blank');
        this.visableEmpresarial = false;
      }, 5000);
    }
    
  }

  cambiarVista(titulo: string, imagen: string, texto: string, experience: number, experience_name: string, videourl: string, lang: string, horario: string) {

    this.changeView(titulo, imagen, texto, experience, experience_name, videourl, lang, horario);

    this.filters.setFiltersLanding({
      exp: experience,
      date: this.storage.get('date') || this.filters.getFiltersLanding().date || new Date().toISOString().split('T')[0],
      adults: 0,
      children: 0
    });
    this.storage.set('exp', experience.toString());
  }

  ngAfterViewInit(): void {
    // Menu experiencia
    let experience_menu = this.el.nativeElement.querySelector("#experiences-title");
    let accionador = this.el.nativeElement.querySelector("#accionador");

    if (experience_menu && accionador) {
      let menuAbierto = false;

      const toggleMenu = () => {
        if (menuAbierto) {
          this.renderer.setStyle(experience_menu, 'display', 'none');
        } else {
          this.renderer.setStyle(experience_menu, 'display', 'block');
        }
        menuAbierto = !menuAbierto;
      };

      this.renderer.listen(accionador, 'click', toggleMenu);
    }
  }
}
