import { Component, Input, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CalendarService } from './calendar.service';
import { LandingService } from '@modules/landing/services/landing.service';
import { DatesService } from '@modules/landing/services/dates.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { PasajerosService } from '../pasajeros/pasajeros.service';
import { FactureService } from '@modules/reservation/services/facture.service';
import { BoatService } from '@modules/reservation/components/service/components/boat/boat.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.css'
})
export class CalendarComponent {
  @Input() width: string = '54%';
  @Input() exp: number = 0;

  constructor(private calendar: CalendarService, private experience: LandingService,
    private dates: DatesService, @Inject(LOCAL_STORAGE) private storage: StorageService,
    private pasajeros: PasajerosService, private facture: FactureService, 
  private boatService: BoatService) { }

  disabledDate: any[] = [];
  now = new Date();
  dateNow = new Date();
  dateNowZero = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), this.dateNow.getDate());

  myCalendar: FormGroup = new FormGroup({});
  dateSelected: Date = new Date();
  ready: boolean = false;

  ngOnInit(): void {
    if(this.exp === 0 || this.exp === null) {
      this.exp = this.storage.get('exp') ? parseInt(this.storage.get('exp')) : 0;
    }
    this.dates.getDisponibilidad$(this.exp, this.now.getMonth() + 1, this.now.getFullYear()).subscribe((data: any) => {
      this.disabledDate = data.diasOcupados.map(
        (dateString: string) => {
          const parts = dateString.split('-');
          const year = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1; // Restar 1 al mes, ya que en JavaScript los meses van de 0 a 11
          const day = parseInt(parts[2], 10);
          return new Date(year, month, day);
        }
      );
      this.ready = true;
    });

    this.calendar.date$.subscribe((date: Date) => {
      this.dateSelected = this.storage.get('date') ? new Date(this.storage.get('date')) : date;

      if (this.exp === 4) {
        this.dates.getHorariosBC$(this.exp, this.dateSelected.toDateString()).subscribe((data: any) => {
          this.calendar.setHorariosBC(data.horasOcupadas);
        });
      }

      if ([2, 5, 6, 7].includes(this.exp)) {
        this.experience.getExperience(this.exp, this.dateSelected.toDateString()).subscribe((data: any) => {
          this.pasajeros.setMaximizePasajeros(data.cupos);
        });
      };

    });

    this.myCalendar = new FormGroup({
      date: new FormControl(this.dateSelected)
    });

  }

  isDisabledDate(current: any): boolean {
    const formattedDate = `${current.day}/${current.month + 1}/${current.year}`;
    const disabledFormattedDate = this.disabledDate.map((date: Date) => {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    });
    return disabledFormattedDate.some(date => date === formattedDate);
  }

  onDateSelect(event: any): void {
    this.storage.set('date', event);
    this.calendar.setDate(event);

    if (this.exp === 0 || this.exp === null) {
      this.exp = this.storage.get('exp') ? parseInt(this.storage.get('exp')) : 0;
    }

    // Bahia privada e islas privadas
    if (this.exp === 3 || this.exp === 1) {
      this.experience.getExperience(this.exp, event).subscribe((data: any) => {
        this.calendar.setBarcosOcupados(data.BarcosOcupados);
        console.log("Entro")
        this.boatService.setPrecios(data.services.map((item: any) => { return item.price_day }))
      });
    }

    this.storage.set('adults', 1);
    this.storage.set('children', 0);

    this.pasajeros.setPasajeros(1, 0);
    this.facture.setAdults(1);
    this.facture.setChildren(0);
  }

  MonthChange(event: any): void {
    if (this.exp === 0 || this.exp === null) {
      this.exp = this.storage.get('exp') ? parseInt(this.storage.get('exp')) : 0;
    }

    this.dates.getDisponibilidad$(this.exp, event.month, event.year).subscribe((data: any) => {
      this.calendar.setDisabledDate(
        data.diasOcupados.map((dateString: string) => {
          const parts = dateString.split('-');
          const year = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1; // Restar 1 al mes, ya que en JavaScript los meses van de 0 a 11
          const day = parseInt(parts[2], 10);
          return new Date(year, month, day);
        })
      );
    });
  }
}
