import { Component, Inject, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FiltersService } from '@modules/landing/services/filters.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { FactureService } from '@modules/reservation/services/facture.service';
import { ServiceAddService } from '@modules/reservation/components/service-add/service-add.service';

@Component({
  selector: 'app-landing-magic',
  templateUrl: './landing-magic.component.html',
  styleUrls: ['./landing-magic.component.css', './responsive.css']
})
export class LandingMagicComponent {
  constructor(private router: Router, private filers: FiltersService, @Inject(LOCAL_STORAGE) private storage: StorageService,
    private factura: FactureService, private adicional: ServiceAddService) { }

  products: any[] = [];

  async ngOnInit() {
    this.adicional.getProducts$().subscribe((products: any[]) => {
      this.products = products;
    });
  }

  rediriectToReservas(id_experience: number, id_moment: number) {
    this.filers.setFiltersLanding({
      exp: id_experience,
      date: this.storage.get('date') || this.filers.getFiltersLanding().date,
      adults: 0,
      children: 0
    });
    this.storage.set('exp', id_experience.toString());
    this.factura.addAdicional({
      id_product: id_moment,
      name: this.products.find(product => product.id_product == id_moment).description,
      price: this.products.find(product => product.id_product == id_moment).price, quantity: 1
    }, 6);
    this.factura.setMomentosMagicos(id_moment, true);
    this.router.navigate(['/reservas']);
  }
}
