import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BoatService {

  constructor() { }

  private precios = new BehaviorSubject<any>([]);
  precios$ = this.precios.asObservable();

  setPrecios(precios: any) {
    this.precios.next(precios);
  }

  
}
