import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable, from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.development';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(private cookies: CookieService, private http: HttpClient) { }

  private from_lang = this.cookies.get('lang') === 'es' ? 'en' : 'es';


  translateText(text: string, targetLang: string): Observable<string> {
    const payload = {
      text: text,
      targetLang: targetLang,
      from_lang: this.from_lang
    };

    return this.http.post<any>(`${environment.apiUrl}util/translate`, payload).pipe(
      map(response => response),
      catchError(() => of('Translation failed'))
    );
  }
}