import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FactureService {

  isBoatSelected: boolean;
  isRangeBPSelected: boolean;
  isICSelected: boolean;
  reservaInfo = new BehaviorSubject<{ price: number, name?: string, priceKid?: number }>({ price: 0, name: '' });
  reservaInfo$ = this.reservaInfo.asObservable();

  nameBoat: string = '';
  BPHoras: number = 0;
  adults: number = 0;
  children: number = 0;
  adicionales: any[] = []; //Adicionales
  momentosMagicos: any[] = []; //Momentos magicos

  constructor(private httpClient: HttpClient, @Inject(LOCAL_STORAGE) private storage: StorageService) {
    this.isBoatSelected = this.storage.get('isBoatSelected') || false;
    this.isRangeBPSelected = this.storage.get('rangeBP') ? true : false;
    this.canEditBP = this.storage.get('rangeBP') ? false : true;
    this.BPicon = this.storage.get('rangeBP') ? 'pi pi-pencil' : 'pi pi-plus';
    this.reservaInfo.next(this.storage.get('reservaInfo') || { price: 0, name: '' });
    this.BPHoras = this.storage.get('bpHoras') || 0;
    this.adults = this.storage.get('adults') || 0;
    this.children = this.storage.get('children') || 0;
    this.nameBoat = this.storage.get('nameBoat') || '';
    this.isICSelected = this.storage.get('isICSelected') || false;
    this.adicionales = this.storage.get('adicionales') || [];
    this.momentosMagicos = this.storage.get('momentosMagicos') || [];
  }

  getIsBoatSelected() {
    return this.isBoatSelected;
  }

  setIsBoatSelected(isBoatSelected: boolean) {
    this.isBoatSelected = isBoatSelected;
  }

  getOcupatedHours$(id_experience: number, id_bote: number, date: string) {
    return this.httpClient.get(environment.apiUrl + 'experience/hoursOccupied/' + id_experience + '/' + id_bote + '/' + date);
  }

  //Rango seleccionado en bahia privada
  // isRangeBPSelected: boolean = false;

  getIsRangeBPSelected() {
    return this.isRangeBPSelected;
  }

  setIsRangeBPSelected(isRangeBPSelected: boolean) {
    this.isRangeBPSelected = isRangeBPSelected;
  }

  //Boton de editar horario BP
  canEditBP: boolean = false;

  getCanEditBP() {
    return this.canEditBP;
  }

  setCanEditBP(canEditBP: boolean) {
    this.canEditBP = canEditBP;
  }

  //Boton de editar horario BP icono
  BPicon: string = 'pi pi-plus';

  getBPicon() {
    return this.BPicon;
  }

  setBPicon(BPicon: string) {
    this.BPicon = BPicon;
  }

  //Informacion de la reserva para la factura

  getReservaInfo() {
    return this.reservaInfo.getValue();
  }

  setReservaInfo(reservaInfo: any) {
    this.reservaInfo.next(reservaInfo);
    // this.reservaInfo = reservaInfo;
  }


  //Bahia privada factura horas para precio

  getBPHoras() {
    return this.BPHoras;
  }

  setBPHoras(BPHoras: number) {
    this.BPHoras = BPHoras;
  }

  getAdults() {
    return this.adults;
  }

  setAdults(adults: number) {
    this.adults = adults;
  }

  getChildren() {
    return this.children;
  }

  setChildren(children: number) {
    this.children = children;
  }

  //Nombre del bote seleccionado
  getNameBoat() {
    return this.nameBoat;
  }

  setNameBoat(nameBoat: string) {
    this.nameBoat = nameBoat;
  }

  getIsICSelected() {
    return this.isICSelected;
  }

  setIsICSelected(isICSelected: boolean) {
    this.isICSelected = isICSelected;
  }

  //Adicionales

  getAdicionales() {
    return this.adicionales;
  }

  addAdicional(adicional: any, id_cat?: number) {
    if(id_cat == 6){

    }
    this.adicionales.push(adicional);
    this.storage.set('adicionales', this.adicionales);
  }

  modifyQuantify(adicional: any) {
    for (let ad of this.adicionales) {
      if (ad.id_product == adicional) {
        ad.quantity--;
        if (ad.quantity == 0) {
          this.removeAdicional(adicional);
        }
      }
    }
    this.storage.set('adicionales', this.adicionales);
  }

  removeAdicional(adicional: any) {
    this.adicionales = this.adicionales.filter(ad => ad.id_product != adicional);
    this.storage.set('adicionales', this.adicionales);
  }

  clearAdicionales() {
    this.adicionales = [];
  }

  getTotalAdicionales(): number {
    let total = 0;
    for (let ad of this.adicionales) {
      total += ad.price * ad.quantity;
    }
    return total;
  }

  //Momentos magicos booleanos

  getMomentosMagicos() {
    return this.momentosMagicos;
  }

  setMomentosMagicos(id_moment: number, value: boolean) {
    this.momentosMagicos[id_moment] = value;
    this.storage.set('momentosMagicos', this.momentosMagicos);
  }

  //Factura global
  getTotalFactura() {
    let total = 0;
    if ([2, 4, 6].includes(parseInt(this.storage.get('exp')))) {
      let totalAdults = this.reservaInfo.getValue().price * this.getAdults();
      let totalChildren = (this.reservaInfo.getValue().priceKid ? this.reservaInfo.getValue().priceKid! : 0) * this.getChildren();
      total = totalAdults + totalChildren + this.getTotalAdicionales();
    }

    if ([1, 5, 7].includes(parseInt(this.storage.get('exp')))) {
      total = this.reservaInfo.getValue().price*1 + this.getTotalAdicionales();
    }
    
    if ([3].includes(parseInt(this.storage.get('exp')))) {
      total = this.reservaInfo.getValue().price * this.getBPHoras() + this.getTotalAdicionales();
    }

    return total;
  }

  private isFactureOpen = new BehaviorSubject<boolean>(false);
  isFactureOpen$ = this.isFactureOpen.asObservable();

  setFactureOpen(value: boolean) {
    this.isFactureOpen.next(value);
  }

  private isFactureBlocked = new BehaviorSubject<boolean>(false);
  isFactureBlocked$ = this.isFactureBlocked.asObservable();

  setFactureBlocked(value: boolean) {
    this.isFactureBlocked.next(value);
  }
}
