<ng-container [formGroup]="form">
    <span class="p-input-icon-right">
        <i class="pi pi-user-plus"></i>
        <input [style]="{'background-color':'white', 'color': '#6B7280','border-radius':'5px', 'border-color': '#009FC9', 'height': '2.48rem',
            'font-size': '.9rem', 'padding-left': '.8rem'}" formControlName="pasajeros" (click)="op.toggle($event)"
            placeholder="{{ 'pasajero' | translate }}" pInputText />
    </span>
</ng-container>

<!-- Pasajeros -->
<p-overlayPanel #op [style]="{'width': '20rem', 'height': 'auto'}" [formGroup]="form" (onHide)="setPasajeros()">
    <ng-template pTemplate="content" [style]="{'padding':'.9rem'}">

        <p>{{ 'adultos' | translate }}</p>
        <p-inputNumber mode="decimal" [min]="1" [max]="maximoPasajerosAdults" [showButtons]="true"
            formControlName="adults" (onInput)="setAdults($event.value)"></p-inputNumber>

        <ng-container *ngIf="exp != 4">
            <p>{{ 'nios_26_aos' | translate }}</p>
            <p-inputNumber mode="decimal" [min]="0" [max]="maximoPasajerosChildren" [showButtons]="true"
                formControlName="children"></p-inputNumber>
        </ng-container>


        <strong>
            <p style="padding: 0; margin: 0%; font-size: .7rem; text-align: center; margin-top: 1%;">{{
                'el_maximo_de_pasajeros_es_de' | translate }} {{maximoPasajerosAdults}}
            </p>
        </strong>

        <div style="display: flex; justify-content: center; margin-top: 1rem;">
            <p-button (click)="op.toggle($event)" (click)="setPasajeros()">{{ 'listo' | translate }}</p-button>
        </div>

    </ng-template>
</p-overlayPanel>