import { Component, AfterViewInit, Renderer2, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import { LanguageService } from '@modules/services/language.service';
import { IUser } from '@core/models/IUser';
import { AuthService } from '@modules/auth/services/auth.service';
import { TypeRole } from '@core/enums/TypeRoles';
import { ViewsService } from '@core/services/views.service';
import { AuthenticationService } from '@core/services/authentication.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements AfterViewInit {
  @Input() user: IUser | undefined;

  constructor(private router: Router, private cookies: CookieService, private translate: TranslateService, private langService: LanguageService,
    private el: ElementRef, private renderer: Renderer2, private auth: AuthService, private view: ViewsService,
    private authenticationService: AuthenticationService) { }

  items: any[] = [];
  menuResponsive: boolean = false;
  isResponsiveView: boolean = false;

  optionsLanguage = [
    { value: 'es', label: 'Español' },
    { value: 'en', label: 'English' }
  ];

  initialsName: string | undefined;
  environment: 'reserva' | 'usuarios' = 'reserva';

  ngOnInit(): void {
    const currentUrl = this.router.url;
    if (currentUrl.startsWith('/dashboard')) {
      this.goHome = true;
    }

    this.view.isResponsiveView$.subscribe((isResponsive: boolean) => {
      this.isResponsiveView = isResponsive;
    });
    this.initialsName = this.user?.nombre || (this.cookies.get('lang') === 'es' ? 'Invitado' : 'Guest');

    this.user ? this.environment = 'usuarios' : this.environment = 'reserva';

    if (this.environment === 'usuarios') {
      this.auth.getRoles$(this.user!.id_user).subscribe((role: any) => {
        if (role.rol === TypeRole.client) {
          this.items = [
            {
              label: 'Menu',
              icon: 'pi pi-fw pi-globe',
              expanded: true,

              items: [
                { label: 'Informacion', icon: 'pi pi-fw pi-chart-pie', routerLink: '/dashboard/client/info' },
                { label: 'Reservas', icon: 'pi pi-fw pi-file', routerLink: '/dashboard/client/reservas' }
              ]
            }
          ];
        }

        if (role.rol === TypeRole.costumer) {
          this.items = [
            {
              label: 'Menu',
              icon: 'pi pi-fw pi-globe',
              expanded: true,

              items: [
                { label: 'Informacion', icon: 'pi pi-fw pi-chart-pie', routerLink: '/dashboard/costumer/info' },
                { label: 'Reservas', icon: 'pi pi-fw pi-file', routerLink: '/dashboard/costumer/reservas' },
                { label: 'Comisiones', icon: 'pi pi-fw pi-money-bill', routerLink: '/dashboard/costumer/comisiones' }
              ]
            }
          ];
        }

        if (role.rol === TypeRole.admin) {
          this.items = [
            {
              label: 'Menu',
              icon: 'pi pi-fw pi-globe',
              expanded: true,

              items: [
                { label: 'Informacion', icon: 'pi pi-fw pi-chart-pie', routerLink: '/dashboard/admon/info' },
                { label: 'Reservas', icon: 'pi pi-fw pi-file', routerLink: '/dashboard/admon/reservas' },
                { label: 'Comisiones', icon: 'pi pi-fw pi-money-bill', routerLink: '/dashboard/admon/comisiones' },
                { label: 'Calendario', icon: 'pi pi-fw pi-users', routerLink: '/dashboard/admon/calendario' },
                { label: 'Parametricas', icon: 'pi pi-fw pi-plus', routerLink: '/dashboard/admon/parametricas' }
              ]
            }
          ];
        }
      });
    }
  }

  selectLanguage = this.cookies.get('lang') || 'es';

  changeLanguage(language: SelectButtonChangeEvent) {
    const lang = language.value;
    this.translate.use(lang); // Asigno lenguaje global
    this.selectLanguage = lang; // Asigno lenguaje local
    this.langService.setLanguaje(lang); // Asigno lenguaje en el servicio
    this.cookies.set('lang', lang); // Asigno lenguaje en las cookies
  }

  goHome = false;

  rediriectToHome() {
    const currentUrl = this.router.url;

    if (currentUrl.startsWith('/dashboard')) {
      this.router.navigate(['/']);
    }
  }

  ngAfterViewInit() {

    var nav: any = this.el.nativeElement.querySelector("#navR");
    var background_menu: any = this.el.nativeElement.querySelector("#back_menu");
    var btn_menu = this.el.nativeElement.querySelector("#btn_menu");
    var back_menu = this.el.nativeElement.querySelector("#back_menu");

    if (nav && background_menu && btn_menu && back_menu) {

      this.renderer.listen(btn_menu, 'click', () => mostrar_menu());
      this.renderer.listen(back_menu, 'click', () => ocultar_menu());

      const mostrar_menu = () => {
        this.renderer.setStyle(nav, 'right', '0px');
        this.renderer.setStyle(background_menu, 'display', 'block');
      };

      const ocultar_menu = () => {
        this.renderer.setStyle(nav, 'right', '-900px');
        this.renderer.setStyle(background_menu, 'display', 'none');
      };
    }
  }

  showMenuResponsive() {
    this.menuResponsive = !this.menuResponsive;
  }

  logout() {
    this.authenticationService.logout();
  }
}
