<div class="header-landing" id="menu">
    <div class="contenedormenu">
        <div id="logo"><img src="../../../../../assets/icons/Logoblanco.svg" alt=""></div>

        <div class="menu">
            <i class="fas fa-bars" id="btn_menu"> </i>
            <div id="back_menu"></div>

            <nav id="nav">
                <ul>
                    <li><a (click)="scrollToSection('experiencias')">{{ 'experiencia' | translate }}</a></li>
                    <li><a (click)="scrollToSection('momentos')">{{ 'magicos' | translate }}</a></li>
                    <li><a (click)="scrollToSection('nosotros')">{{ 'nosotros' | translate }}</a></li>
                    <li><a (click)="scrollToSection('contacto')">{{ 'contacto' | translate }}</a></li>
                </ul>

                <p-selectButton [options]="optionsLanguage" optionLabel="label" optionValue="value" [allowEmpty]="false"
                    (onChange)="changeLanguage($event)" [(ngModel)]="selectLanguage">
                    <ng-template let-option pTemplate="item">
                        <img *ngIf="option.value === 'es'" alt="Spanish" src="../../../../../assets/icons/españa.jpg"
                            style="width: 25%; margin-right: 5px;">
                        <img *ngIf="option.value === 'en'" alt="English" src="../../../../../assets/icons/usa.jpg"
                            style="width: 25%; margin-right: 5px;">
                        {{ option.label }}
                    </ng-template>
                </p-selectButton>

                <div class="perfil" (click)="openDialog()"><i class="pi pi-user" style="margin-right: 10px;">
                    </i>{{ 'invitado' | translate }}</div>
            </nav>

        </div>

    </div>

</div>

<div class="header">
    <div class="sec">
        <div class="text-container">
            <div class="landing-text">
                <h1>{{ 'titulo' | translate }}</h1>
                <div>
                    <h2>{{ 'rosario' | translate }}</h2>
                </div>
            </div>
        </div>

        <div class="landing-input" [formGroup]="form">
            <div class="landing-input-container">

                <div style="margin-left: 2%;" class="select">
                    <p>{{ 'elegir_experiencia' | translate }}</p>

                    <p-dropdown appendTo="body" [options]="experiencias" optionLabel="name"
                        placeholder="{{ 'exp' | translate }}" formControlName="exp" class="cajas"
                        [style]=" {'height': '2.5rem','min-width': '12rem', 'max-width': '12rem'}"
                        (onChange)="experienceSelected($event.value)">
                        <ng-template let-experience pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <img src="{{experience.image}}" style="width: 2.5rem; border-radius: 5px; float: left; margin-left: -15px;
                                            margin-right: 5px;" />
                                <div>{{ experience.name }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>

                <div style="margin-left: -2.5%;" class="selectC">
                    <p>{{ 'fecha_de_reserva' | translate }}</p>
                    <p-calendar [showIcon]="true" appendTo="body" formControlName="date" [minDate]="dateNow"
                        placeholder="{{ 'fecha' | translate }}" [iconDisplay]="'input'"
                        [style]=" { 'width':'80%', 'height': '2.6rem'}" [disabledDates]="disabledDate"
                        (onSelect)="onDateSelect($event)" (onMonthChange)="MonthChange($event)">

                        <ng-template pTemplate="date" let-date>
                            <span [ngStyle]="{
                            backgroundColor: isDisabledDate(date) ? 'red' : 'inherit',
                            color: isDisabledDate(date) ? 'white' : 'inherit',
                            borderRadius: isDisabledDate(date) ? '50%' : 'inherit',
                            padding: isDisabledDate(date) ? '0.5rem' : 'inherit'}">{{date.day}}</span>
                        </ng-template>

                    </p-calendar>
                </div>

                <div style="margin-left: -2.5%;" class="select pasajeros">
                    <p>{{ 'pasajeros' | translate }}</p>
                    <span class="p-input-icon-right">
                        <i class="pi pi-user-plus"></i>
                        <input [style]="{'background-color':'white', 'color': '#6B7280', 'border':'none', 'height': '2.48rem',
                        'font-size': '.9rem', 'text-align': 'left', 'padding-left': '.8rem'}"
                            formControlName="pasajeros" (click)="op.toggle($event)"
                            placeholder="{{ 'pasajero' | translate }}" />
                    </span>
                </div>


                <div class="select code">
                    <p>{{ 'codigo_asesor' | translate }}</p>
                    <input type="number" [style]=" {'height': '2.5rem'} " formControlName="code"
                        placeholder="{{ 'codigo' | translate }}">
                </div>

                <div class="color">
                    <p-button [style]="{'width': '8rem', 'height': '3rem', 'font-size': '.8rem',
                        'padding': '0px 10px'}" [disabled]="form.invalid" (click)="toReservation()">{{
                        'crear_experiencia' | translate }}</p-button>
                </div>

            </div>
        </div>
    </div>


</div>


<!-- Inicio de sesion -->
<p-dialog [visible]="visible" class="login pwd" [style]="{'border-radius': '12px'}" [formGroup]="formLogin"
    [breakpoints]="{ '1199px': '75vw', '575px': '100vw' }">
    <ng-template pTemplate="headless">
        <div class="flex flex-column px-5 py-5 gap-3"
            style="border-radius: 12px;background-image: radial-gradient(circle at left top, #05b2dc, #009FC5);align-items: center;">
            <div id="logo"><img src="../../../../../assets/icons/Logoblanco.svg" alt=""></div>

            <p class="new_user">{{ 'primera_vez' | translate }} <span class="registro" (click)="openRegister()"
                    (click)="closeDialog()">{{ 'registrate' | translate }}</span></p>

            <div class="inline-flex flex-column gap-2" style="align-items: center;">
                <label for="username" class="text-primary-50 font-semibold">{{ 'correo_electronico' | translate
                    }}</label>
                <input pInputText id="username" formControlName="email" style="width: 65%;"
                    class="bg-white-alpha-20 p-3 text-primary-50" />
            </div>
            <div class="inline-flex flex-column gap-2 pwdContainer" style="align-items: center;">
                <label for="password" class="text-primary-50 font-semibold">{{ 'contrasea' | translate }}</label>
                <p-password [toggleMask]="true" [feedback]="false" formControlName="password">
                </p-password>
            </div>

            <div class="flex align-items-center gap-3 separator" style="padding: 0 2rem 0 2rem;">
                <p-button label="{{ 'iniciar_sesion' | translate }}" (click)="login()" [text]="true"
                    [disabled]="formLogin.invalid || formLogin.pending"
                    styleClass="w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
                    class="w-full" [style]="{'margin-left':'-35%'}"></p-button>
                <p-button label="{{ 'cancelar' | translate }}" (click)="closeDialog()" [text]="true"
                    styleClass="w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
                    class="w-full"></p-button>
            </div>
            <p class="forget" (click)="resetPasswordEmail = true; visible = false">{{ 'olvidaste_tu_contrasea' |
                translate }}</p>
        </div>
    </ng-template>
</p-dialog>

<!-- Registro -->
<p-dialog [visible]="register" class="signup view" [style]="{'border-radius': '12px', 'max-width': '80vw'}"
    [formGroup]="formRegister">
    <ng-template pTemplate="headless">
        <div class="flex flex-column px-3 py-5 gap-3 md:px-7"
            style="border-radius: 12px; background-image: radial-gradient(circle at left top, #05b2dc, #009FC5); align-items: center; max-height: 90vh; overflow-y: auto;">

            <div id="logo">
                <img src="../../../../../assets/icons/Logoblanco.svg" alt="">
            </div>

            <p class="new_user text-center">{{ 'diligencie_el_cuestionario_a_continuacion' | translate }}</p>

            <p-selectButton formControlName="type" [options]="typeRegister" optionLabel="label" optionValue="value"
                allowEmpty="false" (onChange)="typeRegisterSelected($event)" />

            <div class="grid w-full" [ngStyle]="{
                'display': isResponsiveView ? 'flex' : 'block',
                'justify-content': isResponsiveView ? 'center' : 'flex-start',
                'margin-left': isResponsiveView ? '-3rem' : '0'}">

                <div class="col-8 md:col-6 lg:col-4">
                    <div class="inline-flex flex-column gap-2">
                        <label for="nombre" class="text-primary-50 font-semibold">{{ 'nombre' | translate }}</label>
                        <input formControlName="nombre" pInputText id="nombre"
                            pTooltip="{{ 'ingrese_nombre' | translate }}" tooltipPosition="right"
                            class="bg-white-alpha-20 p-3 text-primary-50" />
                    </div>
                </div>

                <div class="col-8 md:col-6 lg:col-4">
                    <div class="inline-flex flex-column gap-2">
                        <label for="apellido" class="text-primary-50 font-semibold">{{ 'apellido' | translate }}</label>
                        <input formControlName="apellido" pInputText id="apellido"
                            pTooltip="{{ 'ingrese_apellidos' | translate }}" tooltipPosition="right"
                            class="bg-white-alpha-20 p-3 text-primary-50" />
                    </div>
                </div>

                <div class="col-8 md:col-6 lg:col-4">
                    <div class="inline-flex flex-column gap-2">
                        <label for="email" class="text-primary-50 font-semibold">{{ 'correo_electronico' | translate
                            }}</label>
                        <input formControlName="email" pInputText id="email"
                            pTooltip="{{ 'ingrese_correo' | translate }}" tooltipPosition="right"
                            class="bg-white-alpha-20 p-3 text-primary-50" />
                    </div>
                </div>

                <div class="col-8 md:col-6 lg:col-4">
                    <div class="inline-flex flex-column gap-2">
                        <label for="phone" class="text-primary-50 font-semibold">{{ 'celular' | translate }}</label>
                        <input formControlName="phone" pInputText id="phone" type="number"
                            pTooltip="{{ 'ingrese_telefono' | translate }}" tooltipPosition="right"
                            class="bg-white-alpha-20 p-3 text-primary-50" />
                    </div>
                </div>

                <div class="col-8 md:col-6 lg:col-4">
                    <div class="inline-flex flex-column gap-2">
                        <label for="documento" class="text-primary-50 font-semibold">{{ 'documento' | translate
                            }}</label>
                        <input formControlName="documento" pInputText id="documento" type="number"
                            pTooltip="{{ 'ingrese_documento' | translate }}" tooltipPosition="right"
                            class="bg-white-alpha-20 p-3 text-primary-50" />
                    </div>
                </div>

                <div class="col-8 md:col-6 lg:col-4">
                    <div class="inline-flex flex-column gap-2">
                        <label for="id_tipo_documento" class="text-primary-50 font-semibold">{{ 'tipo_documento' |
                            translate }}</label>
                        <p-dropdown [options]="documentOptions" optionValue="id_tipo_documento"
                            placeholder="{{ 'seleccionar' | translate }}" optionLabel="descripcion"
                            formControlName="id_tipo_documento" id="type" />
                    </div>
                </div>

                <div class="col-8 md:col-6 lg:col-4">
                    <div class="inline-flex flex-column gap-2">
                        <label for="pwdhash" class="text-primary-50 font-semibold">{{ 'contrasea' | translate }}</label>
                        <input formControlName="pwdhash" pInputText id="pwdhash" type="password"
                            pTooltip="{{ 'minimo_8_caracteres' | translate }}" tooltipPosition="right"
                            class="bg-white-alpha-20 p-3 text-primary-50" />
                    </div>
                </div>

                <div class="col-8 md:col-6 lg:col-4">
                    <div class="inline-flex flex-column gap-2">
                        <label for="direccion" class="text-primary-50 font-semibold">{{ 'direccion' | translate
                            }}</label>
                        <input formControlName="direccion" pInputText id="direccion"
                            pTooltip="{{ 'ingrese_direccion' | translate }}" tooltipPosition="right"
                            class="bg-white-alpha-20 p-3 text-primary-50" />
                    </div>
                </div>

            </div>

            <div class="flex flex-column md:flex-row align-items-center gap-2 w-full"
                [ngStyle]="{ 'text-align': isResponsiveView ? 'center' : 'left' }">
                <p-button label="{{ 'registrate' | translate }}" [disabled]="formRegister.invalid"
                    (click)="createRegister()"
                    styleClass="text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
                    class="w-full md:w-auto" />

                <p-button label="{{ 'cancelar' | translate }}" (click)="register = false"
                    styleClass="text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
                    class="w-full md:w-auto" />
            </div>

        </div>
    </ng-template>
</p-dialog>


<!-- Pasajeros -->
<p-overlayPanel #op [style]="{'width': '20rem', 'height': 'auto'}" [formGroup]="form" (onHide)="setPasajeros()">
    <ng-template pTemplate="content" [style]="{'padding':'.9rem'}">

        <p>{{ 'adultos' | translate }}</p>
        <p-inputNumber mode="decimal" [min]="0" [max]="maximizePasajeros" [showButtons]="true" formControlName="adults"
            (onInput)="setAdults($event.value)"></p-inputNumber>

        <ng-container *ngIf="!showKids">
            <p>{{ 'nios_26_aos' | translate }}</p>
            <p-inputNumber mode="decimal" [min]="0" [max]="maximoPasajerosChildren" [showButtons]="true"
                formControlName="children"></p-inputNumber>
        </ng-container>

        <strong>
            <p style="padding: 0; margin: 0%; font-size: .7rem; text-align: center; margin-top: 1%;">{{
                'el_maximo_de_pasajeros_es_de' | translate }} {{maximizePasajeros}}
            </p>
        </strong>

        <div style="display: flex; justify-content: center; margin-top: 1rem;">
            <p-button (click)="op.toggle($event)" (click)="setPasajeros()">{{ 'listo' | translate }}</p-button>
        </div>


    </ng-template>
</p-overlayPanel>

<!-- Experiencia empresarial -->
<p-dialog [visible]="visableEmpresarial" class="login" [style]="{ 'width': '30%' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '100vw' }">
    <ng-template pTemplate="headless">
        <div class="flex flex-column px-7 py-5 gap-3"
            style="border-radius: 12px;background-image: radial-gradient(circle at left top, #05b2dc, #009FC5);align-items: center;">
            <div id="logo"><img src="../../../../../assets/icons/Logoblanco.svg" alt=""></div>

            <label for="username" class="text-primary-50">{{ 'info_empresarial' | translate }}</label>

            <label for="password" class="text-primary-50 font-semibold">{{ 'redirigiendo' | translate }}</label>
            <svg viewBox="25 25 50 50">
                <circle r="20" cy="50" cx="50"></circle>
            </svg>
            <p-button label="{{ 'cancelar' | translate }}" (click)="closeEmpresarial()" [text]="true"
                styleClass="w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
                class="center"></p-button>

        </div>
    </ng-template>
</p-dialog>

<!-- Recuperacion de contraseña -->

<!-- Solicitud de correo -->
<p-dialog [visible]="resetPasswordEmail" class="signup" [style]="{'border-radius': '12px'}">
    <ng-template pTemplate="headless">
        <div class="flex flex-column px-7 py-5 gap-3"
            style="border-radius: 12px;background-image: radial-gradient(circle at left top, #05b2dc, #009FC5);align-items: center;">
            <div id="logo"><img src="../../../../../assets/icons/Logoblanco.svg" alt=""></div>

            <p class="new_user" style="font-weight: 1rem;">{{ 'ingrese_su_correo_para_la_recuperacion_de_la_cuenta' |
                translate }} </p>

            <div class="inline-flex flex-column gap-2">
                <input pInputText id="username" placeholder="{{ 'correo_electronico' | translate }}" [(ngModel)]="email"
                    class="bg-white-alpha-20 border-none p-3 text-primary-50" />
            </div>
            <div class="flex align-items-center gap-2">
                <p-button label="{{ 'recuperar' | translate }}" (click)="generateCode()" [text]="true"
                    styleClass="w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
                    class="w-full" [style]="{'margin-left':'-38%'}"></p-button>

                <p-button label="{{ 'cancelar' | translate }}" (click)="resetPasswordEmail = false" [text]="true"
                    styleClass="w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
                    class="w-full"></p-button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<!-- Solicitud de OTP -->

<p-dialog [(visible)]="resetPasswordOtp" [modal]="true" [breakpoints]="{ '1199px': '75vw', '575px': '100vw' }"
    [resizable]="false" showHeader="false" [style]="{'border-radius': '20px'}" class="otp">
    <i class="pi pi-times"
        style="cursor: pointer; float: right; font-size: larger; margin-top: 1rem; margin-right: -.6rem;"
        (click)="resetPasswordOtp=false"></i>

    <div class="flex flex-column align-items-center">
        <div
            class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem mb-4 mt-5">
            <i class="pi pi-envelope text-5xl"></i>
        </div>
        <div class="font-bold text-xl mb-2">{{ 'recuperacion_de_cuenta' | translate }}</div>
        <p class="text-color-secondary block mb-5">{{ 'por_favor_digite_el_codigo_enviado_a_su_correo' | translate }}
        </p>

        <p-inputOtp [(ngModel)]="code" (onChange)="inputCode($event)" [length]="4" style="gap: 1"
            [integerOnly]="true"></p-inputOtp>
        <p style="font-size: small; padding: 0%; margin: 0%; margin-top: 10px; color: {{msjColor}};">{{msjComprobante |
            traslate: cookies.get('lang') | async}}
        </p>

        <div class="flex justify-content-between mt-5 align-self-stretch">
            <p-button label="{{ 'enviar_codigo' | translate }}" (click)="sendCode()" [disabled]="buttomSend" />
        </div>
    </div>
</p-dialog>

<!-- Cambio de contraseña -->
<p-dialog [visible]="resetPasswordMessage" class="login pwd" [style]="{'border-radius': '12px'}">
    <ng-template pTemplate="headless">
        <div class="flex flex-column px-7 py-5 gap-3"
            style="border-radius: 12px;background-image: radial-gradient(circle at left top, #05b2dc, #009FC5);align-items: center;">
            <div id="logo"><img src="../../../../../assets/icons/Logoblanco.svg" alt=""></div>

            <p class="new_user" style="font-weight: 1rem;">{{ 'ingrese_su_nueva_contrasea' | translate }} </p>

            <div class="inline-flex flex-column gap-2 pwdContainer" style="margin-left: 4.5rem;">
                <label for="password" class="text-primary-50 font-semibold">{{ 'contrasea' | translate }}</label>
                <p-password [toggleMask]="true" [(ngModel)]="pwd">
                </p-password>
            </div>

            <div class="flex align-items-center gap-2">
                <p-button label="{{ 'recuperar' | translate }}" (click)="resetPwd()" [text]="true"
                    styleClass="w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
                    class="w-full" [style]="{'margin-left':'-38%'}"></p-button>

                <p-button label="{{ 'cancelar' | translate }}" (click)="resetPasswordMessage = false" [text]="true"
                    styleClass="w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
                    class="w-full"></p-button>
            </div>
        </div>
    </ng-template>
</p-dialog>